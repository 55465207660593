import { NumberInput, NumberInputProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";
import { t } from "@lingui/core/macro";

export type NumberInput = Omit<MantineFormValues, "onChange"> &
  NumberInputProps;

export const LimeNumberInput = React.forwardRef(
  ({ error, ...props }: NumberInput, ref: React.Ref<HTMLInputElement>) => (
    <NumberInput
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      aria-label={
        typeof props.label === "string" ? props.label : t`Number input`
      }
      {...props}
      onChange={undefined}
      onValueChange={props.onValueChange ?? props.onChange}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
    />
  ),
);
LimeNumberInput.displayName = "LimeNumberInput";
