import { useEffect, useState } from "react";
import {
  AutocompleteItem,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from "@heroui/react";
import { useForm, UseFormReturnType } from "@mantine/form";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../../../shared/utils/utils";
import { cn } from "@/utils";
import { PostOrganizationInvoice } from "@/server-types";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { Check, CircleAlert, Plus, Trash2, X } from "lucide-react";
import { i18n } from "@lingui/core";
import useFeature from "@/hooks/useFeature";
import { useDebouncedState } from "@mantine/hooks";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeNumberInput } from "@/Components/NextBase/LimeNumberInput";

const convertCentsToCurrency = (cents: number | undefined) =>
  cents ? cents / 100 : 0;

type PaymentOption = NonNullable<
  PostOrganizationInvoice["body"]["payments"]
>[number];

export type SplitPaymentForm = {
  options: PaymentOption[];
  createGiftCardOptions: {
    type: NonNullable<
      NonNullable<
        PostOrganizationInvoice["body"]["createGiftCardsData"]
      >["payments"]
    >[number]["type"];
    amountCents: number;
  }[];
};

type SplitPaymentModalProps = {
  isOpen: boolean;
  handleClose: (data?: {
    options: SplitPaymentForm["options"];
    createGiftCardOptions: NonNullable<
      PostOrganizationInvoice["body"]["createGiftCardsData"]
    >["payments"];
  }) => void;
  existingData?: SplitPaymentForm["options"];
  isLoading: boolean;
  currency: string;
  locale: string;
  totalPriceCents: number;
  totalDiscountInvoicePriceCents?: number;
  errorMessage?: string | null;
  hideCoupon?: boolean;
  isChangingPaymentOnExistingInvoice?: boolean;
  createGiftCardSection?: {
    amountCents: number;
  };
};

export const SplitPaymentModal = ({
  isOpen,
  handleClose,
  existingData,
  isLoading,
  currency,
  locale,
  totalPriceCents,
  totalDiscountInvoicePriceCents,
  errorMessage,
  hideCoupon,
  isChangingPaymentOnExistingInvoice,
  createGiftCardSection,
}: SplitPaymentModalProps) => {
  const { isFeatureEnabled } = useFeature();

  const {
    isOpen: isAddCouponToInvoiceModalOpen,
    onOpen: openAddCouponToInvoiceModal,
    onClose: closeAddCouponToInvoiceModal,
  } = useDisclosure();

  const [isRedeemingThirdPartyGiftCard, setIsRedeemingThirdPartyGiftCard] =
    useState(false);

  const form = useForm<SplitPaymentForm>({
    initialValues: {
      options: [],
      createGiftCardOptions: [],
    },
    validate: {
      options: (value) => {
        const { amountLeft } = CalculateValues({
          amountCents: totalDiscountInvoicePriceCents || totalPriceCents,
          enteredOptions: value,
        });

        if (amountLeft !== 0) {
          return t`Preostali znesek mora biti 0`;
        }

        const isAnyGiftCardInvalid = value
          .filter((value) => value.type === "coupon")
          .some(
            (value) =>
              value.type === "coupon" &&
              (!value.giftCardCode ||
                value.giftCardCode.length < 3 ||
                !value.amountCents ||
                value.amountCents < 0),
          );

        if (isAnyGiftCardInvalid) {
          return t`Vsi darilni boni morajo biti veljavni`;
        }

        return null;
      },
      createGiftCardOptions: (value) => {
        const isCreatingAnyGiftCards = createGiftCardSection != null;
        if (!isCreatingAnyGiftCards) return null;

        const { amountLeft } = CalculateValues({
          amountCents: createGiftCardSection.amountCents,
          enteredOptions: value,
        });

        if (amountLeft !== 0) {
          return t`Preostali znesek mora biti 0`;
        }

        return null;
      },
    },
  });

  useEffect(() => {
    if (isLoading) return;
    form.reset();
  }, [isLoading]);

  useEffect(() => {
    if (isLoading || !existingData) return;

    form.setValues({
      options: existingData.map((option) => ({
        ...option,
        amountCents: option.amountCents ?? 0,
      })),
    });
  }, [isLoading, existingData]);

  const { amountLeft, totalAmount } = CalculateValues({
    amountCents: totalDiscountInvoicePriceCents || totalPriceCents,
    enteredOptions: form.getValues().options,
  });

  const { isPrinting } = usePosPrinterStore((state) => state);

  const totalAmountFormatted = formatCurrency({
    amount: totalAmount,
    currency,
    locale,
    fractionDigits: 3,
  });

  const amountLeftFormatted = formatCurrency({
    amount: amountLeft,
    currency,
    locale,
    fractionDigits: 3,
  });

  const currencySymbol = returnCurrencySymbol({ currency });

  const handleChangeOptionAmount = (
    type: PaymentOption["type"],
    amountCents: number,
  ) => {
    const existingOptionIndex = form
      .getValues()
      .options.findIndex((option) => option.type === type);

    if (!amountCents || amountCents == 0) {
      form.removeListItem("options", existingOptionIndex);
      return;
    }

    if (existingOptionIndex >= 0) {
      form.setFieldValue(
        "options",
        form.getValues().options.map((option) => {
          if (option.type === type) {
            return {
              ...option,
              amountCents,
            };
          }
          return option;
        }),
      );
    } else {
      form.insertListItem("options", {
        type,
        amountCents,
      });
    }
  };

  const handleChangeGiftCardOptionAmount = (
    type: PaymentOption["type"],
    amountCents: number,
  ) => {
    const existingOptionIndex = form
      .getValues()
      .createGiftCardOptions.findIndex((option) => option.type === type);

    if (!amountCents || amountCents == 0) {
      form.removeListItem("createGiftCardOptions", existingOptionIndex);
      return;
    }

    if (existingOptionIndex >= 0) {
      form.setFieldValue(
        "createGiftCardOptions",
        form.getValues().createGiftCardOptions.map((option) => {
          if (option.type === type) {
            return {
              ...option,
              amountCents,
            };
          }
          return option;
        }),
      );
    } else {
      form.insertListItem("createGiftCardOptions", {
        type,
        amountCents,
      });
    }
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        classNames={{
          closeButton: "m-2",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();

                form.validate();

                if (form.isValid()) {
                  const values = form.getValues();
                  handleClose(values);
                }
              }}
            >
              <ModalHeader>
                {isChangingPaymentOnExistingInvoice ? (
                  <Trans>Spremeni način plačila</Trans>
                ) : (
                  <Trans>Razdeli plačilo</Trans>
                )}
              </ModalHeader>
              <ModalBody>
                <div className="flex justify-around py-4 text-center">
                  <PaymentInfoBlock
                    label={t`Celoten znesek`}
                    value={totalAmountFormatted}
                  />
                  <PaymentInfoBlock
                    label={t`Preostali znesek`}
                    value={amountLeftFormatted}
                    color={
                      amountLeft === 0
                        ? "success"
                        : amountLeft !== 0
                          ? "error"
                          : undefined
                    }
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <PaymentTypeInputWrapper label={t`Gotovina`}>
                    <LimeNumberInput
                      autoFocus
                      // {...form.getInputProps("options.cash")}
                      value={convertCentsToCurrency(
                        form
                          .getValues()
                          .options.find((option) => option.type === "cash")
                          ?.amountCents,
                      )}
                      onValueChange={(val) =>
                        handleChangeOptionAmount("cash", Number(val * 100))
                      }
                      type="number"
                      step={1}
                      size="sm"
                      endContent={currencySymbol}
                      data-identifier="split-payment-cash-amount-input"
                    />
                  </PaymentTypeInputWrapper>
                  <PaymentTypeInputWrapper label={t`Kartica`}>
                    <LimeNumberInput
                      value={convertCentsToCurrency(
                        form
                          .getValues()
                          .options.find((option) => option.type === "card")
                          ?.amountCents,
                      )}
                      onValueChange={(val) =>
                        handleChangeOptionAmount("card", Number(val * 100))
                      }
                      type="number"
                      step={1}
                      size="sm"
                      endContent={currencySymbol}
                      data-identifier="split-payment-card-amount-input"
                    />
                  </PaymentTypeInputWrapper>

                  <PaymentTypeInputWrapper label={t`Spletno plačilo`}>
                    <LimeNumberInput
                      value={convertCentsToCurrency(
                        form
                          .getValues()
                          .options.find((option) => option.type === "online")
                          ?.amountCents,
                      )}
                      onValueChange={(val) =>
                        handleChangeOptionAmount("online", Number(val * 100))
                      }
                      type="number"
                      step={1}
                      size="sm"
                      endContent={currencySymbol}
                      data-identifier="split-payment-online-amount-input"
                    />
                  </PaymentTypeInputWrapper>

                  {isFeatureEnabled("GIFTCARD") && !hideCoupon ? (
                    <>
                      <Divider />

                      {createGiftCardSection != null ? (
                        <CreateGiftCardSection
                          form={form}
                          totalAmountCents={createGiftCardSection.amountCents}
                          currencySymbol={currencySymbol}
                          handleChangeGiftCardOptionAmount={
                            handleChangeGiftCardOptionAmount
                          }
                          currency={currency}
                          locale={locale}
                        />
                      ) : undefined}

                      <p className="text-sm font-medium">
                        <Trans>Unovčitev darilnih bonov</Trans>
                      </p>
                      {form
                        .getValues()
                        .options.filter((option) => option.type === "coupon")
                        .map((option) => {
                          const { amountCents, giftCardCode } = option;

                          const indexInOptions = form
                            .getValues()
                            .options.findIndex(
                              (option) =>
                                option.type === "coupon" &&
                                option.giftCardCode === giftCardCode,
                            );

                          return (
                            <GiftCardRow
                              key={indexInOptions}
                              existingData={{
                                amountCents: amountCents!,
                                code: giftCardCode!,
                              }}
                              handleDeleteCoupon={() => {
                                form.removeListItem("options", indexInOptions);
                              }}
                            />
                          );
                        })}

                      <div className="flex flex-col gap-2 md:flex-row md:items-center">
                        <Button
                          onPress={() => {
                            setIsRedeemingThirdPartyGiftCard(false);
                            openAddCouponToInvoiceModal();
                          }}
                          variant="light"
                          className="w-fit"
                          startContent={<Plus size={16} />}
                          isDisabled={
                            form
                              .getValues()
                              .options.filter(
                                (option) =>
                                  option.type === "coupon" &&
                                  (option.amountCents == null ||
                                    option.giftCardCode == null),
                              ).length > 0
                          }
                        >
                          <Trans>Unovči darilni bon</Trans>
                        </Button>

                        {isFeatureEnabled("GIFT_CARD_ALLOW_THIRD_PARTY") ? (
                          <Button
                            onPress={() => {
                              setIsRedeemingThirdPartyGiftCard(true);
                              openAddCouponToInvoiceModal();
                            }}
                            variant="light"
                            className="w-fit"
                            startContent={<Plus size={16} />}
                            isDisabled={
                              form
                                .getValues()
                                .options.filter(
                                  (option) =>
                                    option.type === "coupon" &&
                                    (option.amountCents == null ||
                                      option.giftCardCode == null),
                                ).length > 0
                            }
                          >
                            <Trans>Unovči darilni bon drugega podjetja</Trans>
                          </Button>
                        ) : undefined}
                      </div>
                    </>
                  ) : undefined}
                </div>
              </ModalBody>
              <ModalFooter className="flex-col p-2">
                {errorMessage && (
                  <>
                    <Divider />

                    <div>
                      <LimeAlert color="danger" message={errorMessage} />
                    </div>
                  </>
                )}

                <Divider />

                <LimeAlert
                  message={
                    form.errors.options || form.errors.createGiftCardOptions
                  }
                  color={"danger"}
                />

                <Button
                  className="h-12 w-full"
                  isLoading={isLoading || isPrinting}
                  color="primary"
                  type="submit"
                  data-identifier={
                    isChangingPaymentOnExistingInvoice
                      ? "confirm-change-payments"
                      : "split-payment-confirm"
                  }
                >
                  {isChangingPaymentOnExistingInvoice ? (
                    <Trans>Spremeni plačilo</Trans>
                  ) : (
                    <Trans>Ustvari račun</Trans>
                  )}
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>

      <AddCouponToInvoiceModal
        isOpen={isAddCouponToInvoiceModalOpen}
        handleClose={(data) => {
          if (data) {
            form.insertListItem("options", {
              ...data,
            });
          }

          closeAddCouponToInvoiceModal();
        }}
        totalAmount={totalAmount}
        amountLeft={amountLeft}
        existingGiftCardCodes={form
          .getValues()
          .options.filter((option) => option.type === "coupon")
          .map((option) => option.giftCardCode)}
        currencySymbol={currencySymbol}
        isRedeemingThirdPartyGiftCard={isRedeemingThirdPartyGiftCard}
      />
    </>
  );
};

const CreateGiftCardSection = ({
  form,
  totalAmountCents,
  handleChangeGiftCardOptionAmount,
  currencySymbol,
  currency,
  locale,
}: {
  form: UseFormReturnType<SplitPaymentForm>;
  totalAmountCents: number;
  handleChangeGiftCardOptionAmount: (
    type: PaymentOption["type"],
    amountCents: number,
  ) => void;
  currencySymbol: string;
  currency: string;
  locale: string;
}) => {
  const { amountLeft, totalAmount } = CalculateValues({
    amountCents: totalAmountCents,
    enteredOptions: form.getValues().createGiftCardOptions,
  });

  const totalAmountFormatted = formatCurrency({
    amount: totalAmount,
    currency,
    locale,
    fractionDigits: 3,
  });

  const amountLeftFormatted = formatCurrency({
    amount: amountLeft,
    currency,
    locale,
    fractionDigits: 3,
  });

  return (
    <>
      <p className="text-sm font-medium">
        <Trans>Način plačila za nov darilni bon</Trans>
      </p>

      <div className="flex justify-around py-4 text-center">
        <PaymentInfoBlock
          label={t`Celoten znesek bona`}
          value={totalAmountFormatted}
        />
        <PaymentInfoBlock
          label={t`Preostali znesek bona`}
          value={amountLeftFormatted}
          color={
            amountLeft === 0
              ? "success"
              : amountLeft !== 0
                ? "error"
                : undefined
          }
        />
      </div>

      <div className="flex flex-col gap-4">
        <PaymentTypeInputWrapper label={t`Gotovina`}>
          <LimeNumberInput
            autoFocus
            value={convertCentsToCurrency(
              form
                .getValues()
                .createGiftCardOptions.find((option) => option.type === "cash")
                ?.amountCents,
            )}
            onValueChange={(val) =>
              handleChangeGiftCardOptionAmount("cash", Number(val * 100))
            }
            step={1}
            endContent={currencySymbol}
            size="sm"
            data-identifier="split-payment-gift-card-cash-amount-input"
          />
        </PaymentTypeInputWrapper>
        <PaymentTypeInputWrapper label={t`Kartica`}>
          <LimeNumberInput
            value={convertCentsToCurrency(
              form
                .getValues()
                .createGiftCardOptions.find((option) => option.type === "card")
                ?.amountCents,
            )}
            onValueChange={(val) =>
              handleChangeGiftCardOptionAmount("card", Number(val * 100))
            }
            step={1}
            endContent={currencySymbol}
            size="sm"
            data-identifier="split-payment-gift-card-card-amount-input"
          />
        </PaymentTypeInputWrapper>
      </div>

      <Divider />
    </>
  );
};

const GiftCardRow = ({
  existingData,
  handleDeleteCoupon,
}: {
  existingData: {
    code: string;
    amountCents: number;
  };
  handleDeleteCoupon: () => void;
}) => {
  return (
    <div className="flex h-14 gap-2">
      <LimeInput
        value={existingData.code}
        isRequired
        isDisabled
        label={t`Koda`}
      />

      <LimeInput
        value={existingData.amountCents / 100}
        type="number"
        step={0.01}
        isRequired
        isDisabled
        label={t`Vrednost`}
      />

      <Button
        isIconOnly
        onPress={handleDeleteCoupon}
        className="h-full"
        variant="flat"
        color="danger"
      >
        <Trash2 size={20} />
      </Button>
    </div>
  );
};

type GiftCardPaymentOption = PaymentOption & {
  type: "coupon";
};
const AddCouponToInvoiceModal = ({
  isOpen,
  handleClose,
  totalAmount,
  amountLeft,
  existingGiftCardCodes,
  currencySymbol,
  isRedeemingThirdPartyGiftCard,
}: {
  isOpen: boolean;
  handleClose: (data?: GiftCardPaymentOption) => void;
  totalAmount: number;
  amountLeft: number;
  existingGiftCardCodes?: string[];
  currencySymbol?: string;
  isRedeemingThirdPartyGiftCard: boolean;
}) => {
  const giftCardForm = useForm<GiftCardPaymentOption>({
    initialValues: isRedeemingThirdPartyGiftCard
      ? {
          type: "coupon",
          isThirdParty: true as const,
          giftCardPrefix: "",
          giftCardCode: "",
          amountCents: 0,
        }
      : {
          type: "coupon",
          isThirdParty: false as const,
          amountCents: 0,
          giftCardCode: "",
        },

    validate: {
      giftCardPrefix: (value) => {
        if (!isRedeemingThirdPartyGiftCard) return;

        if (!value.length || value.trim().length <= 0) {
          return t`Predpona ni veljavna`;
        }
      },

      giftCardCode: (value) => {
        if (!value || value.length < 3) {
          return t`Koda ni veljavna`;
        }

        if (existingGiftCardCodes?.includes(value)) {
          return t`Darilni bon je že dodan na račun`;
        }
      },

      amountCents: (value) => {
        if (!value) {
          return t`Vrednost je obvezna`;
        }

        const val = Number(value);

        if (val <= 0) {
          return t`Vrednost je obvezna`;
        }

        if (val > amountLeft * 100) {
          return t`Vrednost ne more biti več kot celoten znesek (${amountLeft})`;
        }
      },
    },
  });

  useEffect(() => {
    if (!isOpen) return;

    giftCardForm.reset();
    giftCardForm.setValues({
      giftCardPrefix: "",
      isThirdParty: isRedeemingThirdPartyGiftCard,
    });
  }, [isOpen]);

  const {
    data: giftCardData,
    isFetching: isGiftCardFetching,
    processedErrorMessage: getGiftCardErrorMessage,
  } = api.giftCard.useGetGiftCardDetailsByCode(
    isRedeemingThirdPartyGiftCard ? "" : giftCardForm.values.giftCardCode,
  );

  const [giftCardSearch, setGiftCardSearch] = useDebouncedState("", 200);
  const {
    data: searchedGiftCards,
    isFetching: isSearchedGiftCardsFetching,
    processedErrorMessage: getSearchedGiftCardsErrorMessage,
  } = api.giftCard.useGetGiftCards({
    search: giftCardSearch,
    disabled: isRedeemingThirdPartyGiftCard || giftCardSearch.length < 2,
  });

  useEffect(() => {
    if (giftCardData?.giftCard) {
      giftCardForm.setFieldValue(
        "amountCents",
        Math.min(
          giftCardData.giftCard.remainingBalanceCents / 100 || 0,
          totalAmount,
        ),
      );
    }
  }, [giftCardData]);

  const isGiftCardExpired = isRedeemingThirdPartyGiftCard
    ? false
    : giftCardData?.giftCard?.expiryDate &&
      new Date() > new Date(giftCardData?.giftCard?.expiryDate);

  const renderEndContent = () => {
    if (isGiftCardFetching || isSearchedGiftCardsFetching)
      return <Spinner size="sm" />;

    const isCodeValid =
      giftCardForm.getValues().giftCardCode &&
      giftCardForm.getValues().giftCardCode.length >= 3 &&
      giftCardData;
    if (!isCodeValid || !giftCardData) return null;

    if (giftCardData.found && giftCardData.giftCard) {
      if (isGiftCardExpired) {
        return <CircleAlert color="orange" size={20} />;
      }

      return <Check color="green" size={20} />;
    }

    if (!giftCardData.found) {
      return <X color="red" size={20} />;
    }

    return null;
  };

  const { locale } = i18n;

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={handleClose}
      classNames={{
        closeButton: "m-2",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const valid = giftCardForm.validate();
              if (valid.hasErrors) return;

              if (isRedeemingThirdPartyGiftCard) {
                return handleClose(giftCardForm.getValues());
              }

              const remainingOnCardCents =
                giftCardData?.giftCard?.remainingBalanceCents || 0;

              const isValueInRange =
                Number(giftCardForm.getValues().amountCents) <=
                remainingOnCardCents;

              if (!isValueInRange) {
                giftCardForm.setFieldError(
                  "amountCents",
                  t`Vrednost mora biti med 0 in` +
                    " " +
                    remainingOnCardCents / 100,
                );
              }

              if (giftCardForm.isValid() && isValueInRange) {
                const values = giftCardForm.getValues();
                handleClose(values);
              }
            }}
          >
            <ModalHeader>
              <Trans>Dodaj darilni bon</Trans>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col">
                <LimeAlert color="danger" message={getGiftCardErrorMessage} />

                {isRedeemingThirdPartyGiftCard ? (
                  <div className="flex items-center gap-2">
                    <LimeInput
                      label={t`Predpona`}
                      className="flex-[1]"
                      {...giftCardForm.getInputProps("giftCardPrefix")}
                    />
                    <p>-</p>
                    <LimeInput
                      label={t`Koda`}
                      className="flex-[2]"
                      {...giftCardForm.getInputProps("giftCardCode")}
                    />
                  </div>
                ) : (
                  <LimeAutocomplete
                    items={
                      giftCardSearch.length < 2
                        ? []
                        : (searchedGiftCards?.giftCards || []).map(
                            (giftCard) => ({
                              label: giftCard.code,
                              value: giftCard.code,
                            }),
                          )
                    }
                    label={t`Koda`}
                    endContent={renderEndContent()}
                    placeholder={t`Vnesite kodo darilnega bona`}
                    onInputChange={setGiftCardSearch}
                    selectedKey={giftCardForm.getValues().giftCardCode}
                    onSelectionChange={(value) => {
                      giftCardForm.setFieldValue(
                        "giftCardCode",
                        value?.toString() || "",
                      );
                    }}
                  >
                    {(item) => (
                      <AutocompleteItem key={item.value}>
                        {item.label}
                      </AutocompleteItem>
                    )}
                  </LimeAutocomplete>
                )}

                {giftCardData?.giftCard && isGiftCardExpired && (
                  <p className="mt-1 text-right text-xs text-warning-600">
                    <Trans>Rok darilnega bona je potekel</Trans>{" "}
                    {new Date(
                      giftCardData.giftCard.expiryDate,
                    ).toLocaleDateString(locale)}
                  </p>
                )}

                <LimeNumberInput
                  value={convertCentsToCurrency(
                    giftCardForm.getValues().amountCents,
                  )}
                  onValueChange={(val) => {
                    giftCardForm.setFieldValue(
                      "amountCents",
                      Number(val * 100),
                    );
                  }}
                  error={giftCardForm.errors.amountCents as string | undefined}
                  type="number"
                  step={1}
                  endContent={currencySymbol}
                  isRequired
                  className="mt-4"
                  isDisabled={
                    isRedeemingThirdPartyGiftCard
                      ? false
                      : !giftCardData?.giftCard
                  }
                />

                {giftCardData && giftCardData.found && giftCardData.giftCard ? (
                  <div className="mt-4 text-sm text-gray-500">
                    <p>
                      {t`Preostali znesek na bonu: ${giftCardData.giftCard.remainingBalanceFormatted}`}
                    </p>
                    <p>
                      {t`Datum veljavnosti: ${new Date(
                        giftCardData.giftCard.expiryDate,
                      ).toLocaleDateString(locale)}`}
                    </p>
                    {giftCardData.giftCard.note ? (
                      <p>
                        {t`Opomba:`} {giftCardData.giftCard.note}
                      </p>
                    ) : undefined}
                  </div>
                ) : undefined}

                <LimeAlert
                  color="danger"
                  message={giftCardForm.errors.options as string | undefined}
                />
              </div>
            </ModalBody>
            <ModalFooter className="flex-col p-2">
              <Divider />
              <Button className="h-12 w-full" color="primary" type="submit">
                <Trans>Dodaj darilni bon</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

const CalculateValues = ({
  amountCents,
  enteredOptions,
}: {
  amountCents: number;
  enteredOptions: SplitPaymentForm["options"];
}) => {
  const enteredTotalCents = enteredOptions.reduce((acc, option) => {
    return acc + Number(option.amountCents);
  }, 0);
  const totalAmountCents = amountCents;
  const amountLeftCents = totalAmountCents - enteredTotalCents;

  const totalAmount = Number((totalAmountCents / 100).toFixed(2));
  const amountLeft = Number((amountLeftCents / 100).toFixed(2));

  return {
    totalAmount,
    amountLeft,
  };
};

const PaymentTypeInputWrapper = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => {
  return (
    <div className="flex items-center gap-4">
      <p className="min-w-[33%] text-sm lg:text-base">{label}</p>
      {children}
    </div>
  );
};

const PaymentInfoBlock = ({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color?: "success" | "error";
}) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs font-bold uppercase text-gray-500">{label}</p>
      <p
        className={cn("text-xl font-semibold", {
          "text-green-500": color === "success",
          "text-red-500": color === "error",
        })}
      >
        {value}
      </p>
    </div>
  );
};
