import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimePhoneInput } from "@/Components/NextBase/LimePhoneInput";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { Language } from "@/server-types";
import { FormValidateInput, useForm } from "@mantine/form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { useEffect } from "react";
import {
  Button,
  Divider,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tabs,
  Tab,
} from "@heroui/react";
import { Trans } from "@lingui/react/macro";
import { LimeModal } from "./LimeModal";
import { toast } from "sonner";
import { LimeTextarea } from "./LimeTextarea";
import { numberAndCountryCodeToFullPhoneNumber } from "@/Utilities";
import { LimeCountryPicker } from "./LimeCountryPicker";

export type EntityType = "physical" | "legal";

export const ManageCustomerModal = ({
  isOpen,
  handleClose,
  editingEntityId,
  selectedEntityType,
}: {
  isOpen: boolean;
  handleClose: ({
    customerId,
    companyId,
  }: {
    customerId?: number;
    companyId?: number;
  }) => void;
  editingEntityId?: number;
  selectedEntityType: EntityType;
}) => {
  type PhysicalEntity = {
    personType: "physical";
    name: string;
    lastName: string;
    gsm: string;
    email: string;
    language: Language;
    notes: string;
  };

  type LegalEntity = {
    personType: "legal";
    taxNumber: string;
    companyName: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    isTaxSubject: boolean;
    language: Language;
    email: string;
  };

  type CustomerForm = PhysicalEntity | LegalEntity;

  const physicalEntityValidators: FormValidateInput<PhysicalEntity> = {
    name: (value) => (value ? null : t`Obvezno polje`),
    lastName: (value) => (value ? null : t`Obvezno polje`),
  };

  const legalEntityValidators: FormValidateInput<LegalEntity> = {
    companyName: (value) => {
      if (!value) return "Obvezno polje";
    },

    address: (value) => {
      if (!value) return "Obvezno polje";
    },

    zipCode: (value) => {
      if (!value) return "Obvezno polje";
    },

    city: (value) => {
      if (!value) return "Obvezno polje";
    },

    country: (value) => {
      if (!value) return "Obvezno polje";
    },

    taxNumber: (value) => {
      if (!value) return "Obvezno polje";
    },
  };

  const { data: existingCustomerData } = api.customer.useGetCustomers({
    customerId: selectedEntityType === "physical" ? editingEntityId : undefined,
  });

  const { data: existingCompanyData } = api.company.useGetCompanies({
    companyId: selectedEntityType === "legal" ? editingEntityId : undefined,
  });

  const form = useForm<CustomerForm>({
    initialValues: {
      personType: selectedEntityType ?? "physical",
      name: "",
      lastName: "",
      gsm: "",
      email: "",
      taxNumber: "",
      companyName: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      notes: "",
      isTaxSubject: false,
      language: "en" as Language,
    },

    validate: (selectedEntityType === "physical"
      ? physicalEntityValidators
      : legalEntityValidators) as FormValidateInput<CustomerForm>, // Cast here so we have typescript completions in the validators above
  });

  useEffect(() => {
    if (
      selectedEntityType !== "physical" ||
      !existingCustomerData ||
      !isOpen ||
      editingEntityId == null
    ) {
      return;
    }

    const customer = existingCustomerData[0];
    if (!customer) return;

    const fullGsm = numberAndCountryCodeToFullPhoneNumber(
      customer.gsm,
      customer.countryCode,
    );

    form.setValues({
      personType: "physical",
      ...customer,
      gsm: fullGsm ?? undefined,
      email: customer.email ?? undefined,
      notes: customer.notes ?? undefined,
    });
  }, [isOpen, existingCustomerData, editingEntityId]);

  useEffect(() => {
    if (
      selectedEntityType !== "legal" ||
      !existingCompanyData ||
      !isOpen ||
      editingEntityId == null
    ) {
      return;
    }

    const company = existingCompanyData[0];
    if (!company) return;

    form.setValues({
      personType: "legal",
      ...company,
      email: company.email ?? undefined,
    });
  }, [isOpen, existingCompanyData, editingEntityId]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }

    if (isOpen) {
      if (selectedEntityType) {
        form.setFieldValue("personType", selectedEntityType);
      }
    }
  }, [isOpen]);

  const {
    mutateAsync: createCustomer,
    isPending: isCreatingCustomer,
    processedErrorMessage: createCustomerErrorMessage,
  } = api.customer.useAddCustomer();

  const {
    mutateAsync: updateCustomer,
    isPending: isUpdatingCustomer,
    processedErrorMessage: updateCustomerErrorMessage,
  } = api.customer.usePutCustomer();

  const {
    mutateAsync: createCompany,
    isPending: isCreatingCompany,
    processedErrorMessage: createCompanyErrorMessage,
  } = api.company.usePostCompany();

  const {
    mutateAsync: updateCompany,
    isPending: isUpdatingCompany,
    processedErrorMessage: updateCompanyErrorMessage,
  } = api.company.usePutCompany();

  const {
    data: languages,
    isFetching: isFetchingLanguages,
    processedErrorMessage: languagesErrorMessage,
  } = api.values.useGetLanguages();

  const handleSubmit = async (values: CustomerForm) => {
    if (values.personType === "physical") {
      const customerId = await (async () => {
        if (editingEntityId == null) {
          const response = await createCustomer(values);

          toast.success(t`Stranka je bila uspešno ustvarjena`);

          return response.data;
        } else {
          await updateCustomer({
            customerId: editingEntityId,
            body: values,
          });

          toast.success(t`Stranka je bila uspešno posodobljena`);

          return editingEntityId;
        }
      })();

      handleClose({ customerId });
    } else if (values.personType === "legal") {
      const companyId = await (async () => {
        if (editingEntityId == null) {
          const response = await createCompany(values);

          toast.success(t`Podjetje je bilo uspešno ustvarjeno`);

          return response.companyId;
        } else {
          await updateCompany({
            companyId: editingEntityId,
            body: values,
          });

          toast.success(t`Podjetje je bilo uspešno posodobljeno`);

          return editingEntityId;
        }
      })();

      handleClose({ companyId });
    }
  };

  const formValues = form.getValues();

  return (
    <LimeModal size="xl" isOpen={isOpen} onClose={() => handleClose({})}>
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader className="flex-col">
              {editingEntityId != null ? (
                <Trans>Uredi stranko</Trans>
              ) : (
                <Trans>Dodaj novo stranko</Trans>
              )}

              {editingEntityId != null ? undefined : (
                <div className="mb-6 mt-8 flex justify-center">
                  <Tabs
                    radius="full"
                    selectedKey={form.getValues().personType}
                    onSelectionChange={(type) =>
                      form.setFieldValue(
                        "personType",
                        type as CustomerForm["personType"],
                      )
                    }
                    disableAnimation
                  >
                    <Tab
                      key={"physical"}
                      title={t`Fizična oseba`}
                      className="px-4"
                    />
                    <Tab
                      key={"legal"}
                      title={t`Pravna oseba`}
                      className="px-4"
                    />
                  </Tabs>
                </div>
              )}
            </ModalHeader>
            <ModalBody>
              {formValues.personType === "physical" ? (
                <>
                  <LimeInput
                    label={t`Ime`}
                    variant="bordered"
                    {...form.getInputProps("name")}
                    autoFocus
                    data-identifier="first-name"
                  />
                  <LimeInput
                    label={t`Priimek`}
                    variant="bordered"
                    {...form.getInputProps("lastName")}
                    data-identifier="last-name"
                  />

                  <LimePhoneInput
                    {...form.getInputProps("gsm")}
                    defaultCountry={"SI"}
                    label={t`Telefon`}
                    error={
                      formValues.gsm && !isValidPhoneNumber(formValues.gsm)
                        ? t`Telefonska številka ni veljavna`
                        : undefined
                    }
                    data-identifier="phone"
                  />

                  <LimeInput
                    label={t`Email`}
                    variant="bordered"
                    {...form.getInputProps("email")}
                    data-identifier="email"
                  />

                  <LimeTextarea
                    label={t`Komentar`}
                    variant="bordered"
                    {...form.getInputProps("notes")}
                  />
                </>
              ) : (
                <>
                  <LimeInput
                    label={t`Davčna številka podjetja`}
                    variant="bordered"
                    {...form.getInputProps("taxNumber")}
                    data-identifier="company-tax-number"
                  />
                  <LimeInput
                    label={t`Ime podjetja`}
                    variant="bordered"
                    {...form.getInputProps("companyName")}
                    data-identifier="company-name"
                  />
                  <LimeInput
                    label={t`Naslov podjetja`}
                    variant="bordered"
                    {...form.getInputProps("address")}
                    data-identifier="company-address"
                  />
                  <LimeInput
                    label={t`Pošta podjetja`}
                    variant="bordered"
                    {...form.getInputProps("zipCode")}
                    data-identifier="company-zip"
                  />
                  <LimeInput
                    label={t`Kraj sedeža podjetja`}
                    variant="bordered"
                    {...form.getInputProps("city")}
                    data-identifier="company-city"
                  />
                  <LimeCountryPicker
                    value={formValues.country}
                    onChange={(value) => form.setFieldValue("country", value)}
                    variant="bordered"
                    label={t`Država sedeža podjetja`}
                    data-identifier="company-country"
                  />
                  <LimeInput
                    label={t`Email`}
                    variant="bordered"
                    {...form.getInputProps("email")}
                    data-identifier="company-email"
                  />
                  <LimeSwitch
                    title={t`Davčni zavezanec`}
                    {...form.getInputProps("isTaxSubject", {
                      type: "checkbox",
                    })}
                    className="rounded-xl"
                    data-identifier="company-is-tax-subject"
                  ></LimeSwitch>
                </>
              )}

              <LimeSelect
                items={
                  languages?.map((language) => ({
                    label: language,
                    key: language,
                  })) || []
                }
                {...form.getInputProps("language")}
                label={t`Jezik`}
                variant="bordered"
                isLoading={isFetchingLanguages}
                errorMessage={form.errors.language ?? languagesErrorMessage}
              />

              {createCustomerErrorMessage && (
                <LimeAlert
                  color="danger"
                  message={createCustomerErrorMessage}
                />
              )}

              {createCompanyErrorMessage && (
                <LimeAlert color="danger" message={createCompanyErrorMessage} />
              )}
            </ModalBody>
            <ModalFooter className="flex-col p-2">
              <Divider className="mt-2" />

              <LimeAlert
                color="danger"
                message={
                  updateCustomerErrorMessage || updateCompanyErrorMessage
                }
                className="my-2"
              />

              <Button
                type="submit"
                className="h-12 w-full font-semibold"
                isLoading={
                  isCreatingCustomer ||
                  isCreatingCompany ||
                  isUpdatingCustomer ||
                  isUpdatingCompany
                }
                data-identifier="confirm-button"
              >
                {editingEntityId == null ? (
                  <Trans>Dodaj novo stranko</Trans>
                ) : (
                  <Trans>Uredi stranko</Trans>
                )}
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </LimeModal>
  );
};
