import { t } from "@lingui/core/macro";
import { countries } from "countries-list";
import ReactCountryFlag from "react-country-flag";
import { MantineFormValues } from "./shared.types";
import { LimeAutocomplete, LimeAutocompleteProps } from "./LimeAutocomplete";
import { AutocompleteItem } from "@heroui/react";
import { useMemo } from "react";

type LimeCountryPickerProps = Omit<
  MantineFormValues & LimeAutocompleteProps<{ label: string; key: string }>,
  "children" | "items" | "onChange"
> & {
  onChange: (value: string) => void;
};

export const LimeCountryPicker = ({
  value,
  onChange,
  ...props
}: LimeCountryPickerProps) => {
  const countryItems = useMemo(
    () =>
      Object.entries(countries).map(([key, country]) => ({
        label: country.name,
        key,
        startContent: (
          <ReactCountryFlag
            countryCode={key}
            aria-label={`Flag of ${country.name}`}
          />
        ),
      })),
    [],
  );

  return (
    <LimeAutocomplete<{ label: string; key: string }>
      label={t`Država`}
      defaultItems={countryItems}
      value={value}
      onValueChange={(value) => onChange(value)}
      {...props}
    >
      {(item) => (
        <AutocompleteItem
          key={item.key}
          startContent={<ReactCountryFlag countryCode={item.key} />}
        >
          {item.label}
        </AutocompleteItem>
      )}
    </LimeAutocomplete>
  );
};
